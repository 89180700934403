import Img from "gatsby-image"
import { Link } from "gatsby"
import React from "react"
import { css } from "@emotion/react"
import {setCurrentBook} from "../../tools/LocalStorage";

const Flashcard = ({ ebook }) => {
  return (
    <div css={FlashcardStyles}>
      <div className="wrapper">
        <Link to={ebook.frontmatter.slug}>
          <Img
            className="one"
            placeholderStyle={{
              width: "100%",
              display: "block",
              objectFit: "contain",
              position: "unset",
            }}
            fluid={ebook.frontmatter.ebook_image.childImageSharp.fluid}
            alt="ebook"
          />
        </Link>
        <div className="two">
          <div className="titles">
            <h1 className="title-1">{ebook.frontmatter.title_1}</h1>
            <h1 className="title-2">{ebook.frontmatter.title_2}</h1>
          </div>
          <h1 className="accroche">{ebook.frontmatter.accroche}</h1>
          <div>
            <Link to={`/free-guides/${ebook.frontmatter.slug}/`} onClick={()=>setCurrentBook(ebook)}>
              View Details >
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Flashcard

const FlashcardStyles = css`
  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    grid-template-areas: "image action";

    margin: auto;
    max-width: 1000px;
    padding: 50px;
  }
  .one {
    grid-area: image;
    width: 100%;
    height: 100%;
  }

  .two {
    grid-area: action;
    display: grid;
    align-content: center;
    a {
      display: inline-grid;
      align-content: center;
      margin-top: 20px;
      padding: 0 15px;
      height: 46px;
      width: fit-content;
      text-decoration: none;
      text-align: left;
      font-weight: 600;
      background-color: #133c9e;
      color: white;
      cursor: pointer;
      border-radius: 10px;
      box-shadow: 5px 8px 8px rgb(0 0 0 / 20%);
    }
  }

  .accroche {
    font-size: 20px;
    font-weight: 400;
  }

  .title-2 {
    font-size: 30px;
    color: #133c9e;
    margin: 0 20px;
  }

  .title-1 {
    font-size: 20px;
    margin: 0 20px;
  }

  .titles {
    border-left: thick solid #133c9e;
  }

  @media screen and (max-width: 600px) {
    .wrapper {
      grid-template-columns: 1fr;
      grid-template-areas:
        "image"
        "action";
      padding: 20px;
    }

    img {
      width: 100%;
    }

    .two {
      text-align: center;
      margin: auto;
    }

    .titles {
      margin: auto;
      text-align: left;
    }

    .title-2 {
      font-size: 25px;
      color: #133c9e;
      margin: auto 10px;
    }

    .title-1 {
      font-size: 15px;
      margin: auto 10px;
    }
  }
`
