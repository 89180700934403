import React from "react"

import Flashcard from "../components/Cards/flashcard"
import { graphql } from "gatsby"
import BasicLayout from "../layouts/BasicLayout"
import NavBarWrapper from "../components/Bar/NavBarWrapper"
import Footer from "../components/Bar/footer"
import { SEO_OPTIONS_FREE_GUIDES } from "../const/SEOOptions"

export default function FreeGuides({ data, location }) {

  return (
    <BasicLayout
      SEOOptions={SEO_OPTIONS_FREE_GUIDES}
    >
      <div 
      >
        <NavBarWrapper background={"white"} location={location}/>
        <div>
          <div className="ebooks-list">
            {data.allMdx.edges.map(({ node }) => {
              // Show ebook only if ispublished frontmatter variable is set to true
              if (node.frontmatter.ispublished && !node.frontmatter.isexpired) {
                return (
                  <div key={node.frontmatter.id}>
                    <Flashcard ebook={node} />
                  </div>
                )
              }
            })}
          </div>
        </div>
        <Footer location={location} isFullpage={false}/>
      </div>
    </BasicLayout>
  )
}

export const query = graphql`
  { 
    logo: file(relativePath: { eq: "main-logo-blue.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    headerImage: file(relativePath: { eq: "data-management.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    healthStudyImage: file(
      relativePath: { eq: "health-study-devices-fill.png" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  allMdx(sort: { order: DESC, fields: frontmatter___slug }) {      edges {
        node {
          id
          frontmatter {
            slug
            id
            ebook_image {
              childImageSharp {
                fluid {
                  tracedSVG
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
              }
            }
            title_1
            title_2
            accroche
            description_1
            description_2
            ispublished
            datepublished
            isexpired
            document {
              publicURL
            }
          }
        }
      }
    }
  }
`
